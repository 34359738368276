<template>
  <div class="header">
    <div class="on-off">
      <img src="../assets/img/header/fold.png" @click="sedData" v-if="$store.state.sidebarStatus" />
      <img src="../assets/img/header/unfold.png" @click="sedData" v-else />
    </div>
    <div class="header-line"></div>
    <div class="up-show">
      <p class="up-show-big">
        视频监测
      </p>
    </div>
    <div class="input-serch">
      <input class="input" type="text" v-model="serch.input" placeholder="信息标题模糊搜索">
      <div class="serch-button" @click="inputSerch">搜索</div>
    </div>
    <div class="header-button-right">
      <div :class="{ 'header-button': true, 'active': warinng }" @click="activeTab(0)"><img
          src="../assets/img/headerVideo/warinng.png" /> 已预警</div>
      <div :class="{ 'header-button': true, 'active': copy }" @click="activeTab(1)"><img
          src="../assets/img/headerVideo/copy.png" />隐藏重复</div>
      <!-- <div :class="{ 'header-button': true, 'active': collect }" @click="activeTab(2)"><img
          src="../assets/img/headerVideo/collect.png" />已收藏</div> -->
      <div class="header-more" @click="drawer = true"><img src="../assets/img/headerVideo/expand.png" /><span>更多筛选</span>
      </div>
    </div>
    <el-drawer :with-header="false" class="viedo-drawer" :show-close="false" :visible.sync="drawer"
      :close-on-press-escape="false" direction="rtl" :wrapperClosable="true" :modal-append-to-body="false" size="306px">
      <div class="viedo-drawer-header" @click="drawer = false">
        <div class="viedo-drawer-header-sq">
          <img src="../assets/img/headerVideo/open.png">
          <span>收起</span>
        </div>
      </div>
      <div class="viedo-drawer-cont">
        <!-- <span class="viedo-drawer-title">信息来源</span>
        <div class="viedo-drawer-card-flex">
          <div :class="{ 'viedo-drawer-card': true, 'active': x.active }" @click="cardActive(x)" v-for="(x, i) in card1"
            :key="'card1' + i">{{ x.name }}</div>
        </div> -->
        <span class="viedo-drawer-title">舆情调性</span>
        <div class="viedo-drawer-card-flex">
          <div :class="{ 'viedo-drawer-card': true, 'active': x.active }" @click="cardActive(x, card2)"
            v-for="(x, i) in card2" :key="'card2' + i">{{ x.name }}</div>
        </div>
        <span class="viedo-drawer-title">监测区域</span>
        <div class="viedo-drawer-card-flex">
          <div :class="{ 'viedo-drawer-card': true, 'active': x.active }" @click="cardActive2(x, card3)"
            v-for="(x, i) in card3" :key="'card3' + i">{{ x.name }}</div>
        </div>
        <span class="viedo-drawer-title">媒体属性</span>
        <div class="viedo-drawer-card-flex">
          <div :class="{ 'viedo-drawer-card': true, 'active': x.active }" @click="cardActive(x, card4)"
            v-for="(x, i) in card4" :key="'card4' + i">{{ x.name }}</div>
        </div>
        <span class="viedo-drawer-title">媒体级别</span>
        <div class="viedo-drawer-card-flex">
          <div :class="{ 'viedo-drawer-card': true, 'active': x.active }" @click="cardActive(x, card5)"
            v-for="(x, i) in card5" :key="'card5' + i">{{ x.name }}</div>
        </div>
        <el-cascader class="eventing" placeholder="请选择事件" :props="props" clearable v-model="eventing"></el-cascader>
        <div class="viedo-drawer-checkbox">
          <div class="viedo-drawer-checkbox-c" @click="activeTab2(0)">
            <img src="../assets/img/headerVideo/checkbox.png" v-if="!warinng" />
            <img src="../assets/img/headerVideo/checkbox-active.png" v-if="warinng" />
            <span>已预警</span>
          </div>
          <div class="viedo-drawer-checkbox-c" @click="activeTab2(1)">
            <img src="../assets/img/headerVideo/checkbox.png" v-if="!copy" />
            <img src="../assets/img/headerVideo/checkbox-active.png" v-if="copy" />
            <span>隐藏重复</span>
          </div>
          <!-- <div class="viedo-drawer-checkbox-c" @click="activeTab(2)">
            <img src="../assets/img/headerVideo/checkbox.png" v-if="!collect" />
            <img src="../assets/img/headerVideo/checkbox-active.png" v-if="collect" />
            <span>已收藏</span>
          </div> -->
        </div>
      </div>
      <div class="viedo-drawer-foot">
        <div class="viedo-drawer-button1" @click="serchYes">查询</div>
        <div class="viedo-drawer-button2" @click="resetting">重置</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { conditionList } from "../api/message/list";
import { getIncident } from "../api/message/list";
export default {
  data() {
    return {
      serch: {
        input: ""
      },
      card1: [
        {
          name: '全部',
          active: true
        }, {
          name: '抖音',
          active: true
        }, {
          name: '快手',
          active: true
        }
      ],
      card2: [
        {
          name: '全部',
          active: true
        }, {
          name: '负面',
          active: true
        }, {
          name: '中性',
          active: true
        }, {
          name: '正面',
          active: true
        }
      ],
      card3: [],
      card4: [],
      card5: [],
      warinng: false,
      copy: false,
      collect: false,
      drawer: false,
      eventing: "",
      props: {
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { level } = node;
          let data = {};
          if (node.level == 0) {
            data.parentId = 1;
          }
          if (node.level != 0) {
            data = {
              parentId: node.data.eventId,
            };
          }
          if (node.level < 3) {
            const res = await getIncident(data);
            if (res != undefined) {
              if (res.data.data.length == 0) {
                node.data.leaf = true;
                return resolve([]);
              } else {
                const nodes = res.data.data.map((item) => ({
                  value: item,
                  label: item.eventName,
                  eventId: item.eventId,
                  leaf: level >= 0,
                }));
                resolve(nodes);
              }
            }
          } else {
            node.data.leaf = true;
            return resolve([]);
          }
        },
      },
    };
  },
  methods: {
    async preciseQuery() {
      const res = await conditionList({
        areaId: this.$store.state.user.areaId,
        organizationId: this.$store.state.user.organizationId
      });
      res.data.data.regional.forEach((x) => {
        this.card3.push({
          name: x.placeName,
          value: x.areaId,
          active: false
        })
      });
      res.data.data.media.forEach((x) => {
        this.card4.push({
          name: x.mediaClassifyName,
          active: true
        })
      });
      res.data.data.mediaLevel.forEach((x) => {
        this.card5.push({
          name: x.mediaClassifyName,
          active: true
        })
      });
    },
    inputSerch() {
      this.$parent.$children.forEach((x) => {
        if (x.$el.className == "video-module clear") {
          x.searchList({ title: this.serch.input, isEarlyWarn: this.warinng, dataExist: this.copy });
        }
      })
    },
    cardActive(x, data) {
      x.active = !x.active;
      if (x.name == "全部") {
        data.forEach((n) => {
          n.active = x.active
        });
      } else {
        let sum = 0;
        data.forEach((n) => {
          if (n.active && n.name != "全部") {
            sum++;
          }
        });
        if (sum == data.length - 1) {
          data.forEach((n) => {
            n.active = true;
          });
        } else {
          data.forEach((n) => {
            if (n.name == "全部") {
              n.active = false;
            }
          });
        }
      }
    },
    cardActive2(x, data) {
      if (x.active) {
        x.active = false;
      } else {
        data.forEach((n) => {
          n.active = false;
        });
        x.active = true;
      }
    },
    serchYes() {
      this.drawer = false;
      this.$parent.$children.forEach((x) => {
        if (x.$el.className == "video-module clear") {
          let data = { title: this.serch.input, isEarlyWarn: this.warinng, dataExist: this.copy };
          //舆情调性
          let tonalState = [];
          if (!this.card2[0].active) {
            this.card2.forEach((x) => {
              if (x.name != "全部" && x.active) {
                if (x.name == "负面") {
                  tonalState.push("2")
                }
                if (x.name == "中性") {
                  tonalState.push("1")
                }
                if (x.name == "正面") {
                  tonalState.push("0")
                }
              }
            });
            data.tonalState = tonalState.join(",");
          }
          //监测区域
          this.card3.forEach((x) => {
            if (x.active) {
              data.areaInvolved = x.value;
            }
          });
          //媒体属性
          let mediaAttribute = [];
          if (!this.card4[0].active) {
            this.card4.forEach((x) => {
              if (x.name != "全部" && x.active) {
                mediaAttribute.push(x.name);
              }
            });
            data.mediaAttribute = mediaAttribute.join(",");
          }
          //媒体级别
          let mediaLevel = [];
          if (!this.card5[0].active) {
            this.card5.forEach((x) => {
              if (x.name != "全部" && x.active) {
                mediaLevel.push(x.name);
              }
            });
            data.mediaLevel = mediaLevel.join(",");
          }
          // 事件类型
          if (this.eventing.length > 0) {
            data.eventClass = this.eventing[0].eventName;
          }
          x.searchList(data);
        }
      })
    },
    resetting() {
      this.warinng = false;
      this.copy = false;
      this.collect = false;
      this.card1.forEach((x) => {
        x.active = true;
      });
      this.card2.forEach((x) => {
        x.active = true;
      });
      this.card3.forEach((x) => {
        x.active = false;
      });
      this.card4.forEach((x) => {
        x.active = true;
      });
      this.card5.forEach((x) => {
        x.active = true;
      });
      this.eventing = "";
    },
    sedData() {
      this.$store.commit("sedData");
    },
    activeTab(type) {
      if (type == 0) {
        this.warinng = !this.warinng;
        this.$parent.$children.forEach((x) => {
          if (x.$el.className == "video-module clear") {
            x.searchList({ title: this.serch.input, isEarlyWarn: this.warinng, dataExist: this.copy });
          }
        })
      } else if (type == 1) {
        this.copy = !this.copy;
        this.$parent.$children.forEach((x) => {
          if (x.$el.className == "video-module clear") {
            x.searchList({ title: this.serch.input, isEarlyWarn: this.warinng, dataExist: this.copy });
          }
        })
      } else if (type == 2) {
        this.collect = !this.collect
      }
    },
    activeTab2(type) {
      if (type == 0) {
        this.warinng = !this.warinng;
      } else if (type == 1) {
        this.copy = !this.copy;
      } else if (type == 2) {
        this.collect = !this.collect
      }
    }
  },
  created() {
    this.preciseQuery();
  },
  mounted() { }
};
</script>

<style lang="scss" scoped>
.eventing {
  margin: 16px 0 0 16px;
}

.viedo-drawer-checkbox-c {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
}

.viedo-drawer-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  margin-top: 16px;

  span {
    font-size: 14px;
    color: #FFFFFF;
  }
}

.viedo-drawer-button1 {
  padding: 6px 16px;
  background: #317CEF;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  opacity: .8;
}

.viedo-drawer-button2 {
  padding: 6px 16px;
  background: #FFF;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
  opacity: .8;
}

.viedo-drawer-button1:hover,
.viedo-drawer-button2:hover {
  opacity: 1;
}

.viedo-drawer-header {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #4A4A4D;
  display: flex;
  align-items: center;

  .viedo-drawer-header-sq {
    margin-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 14px;
      color: #FFFFFF;
      margin-left: 8px;
    }
  }
}

.viedo-drawer-cont {
  overflow-y: scroll;
  height: calc(100% - 144px);
  padding-bottom: 16px;

  .viedo-drawer-title {
    font-size: 16px;
    color: #999999;
    padding-left: 16px;
    margin-top: 16px;
    display: inline-block;
  }

  .viedo-drawer-card-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .viedo-drawer-card {
      padding: 6px 12px;
      background: #4D4F5B;
      border-radius: 4px;
      margin: 16px 0 0 16px;
      cursor: pointer;
      font-size: 14px;
      color: #FFFFFF;
      opacity: .8;
    }

    .viedo-drawer-card:hover {
      opacity: 1;
    }

    .viedo-drawer-card.active {
      background: #EEF0F5;
      color: #333333;
    }
  }
}

.viedo-drawer-foot {
  width: 100%;
  height: 64px;
  border-top: 1px solid #4A4A4D;
  position: absolute;
  bottom: 0;
  background-color: rgba(37, 38, 50, 1);
  padding: 16px 24px;
  display: flex;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #6a6c79;
  border-radius: 4px;
  transition: all 1s;
  width: 16px;
}


@font-face {
  font-family: "fz";
  src: url("../assets/font/FZZCHJW.otf");
  font-weight: normal;
  font-style: normal;
}

.header {
  height: 100%;
  width: 100%;
  background: #181A25;
  padding: 0;
  min-width: 1625px;
  z-index: 9999;
  border-bottom: 1px solid #4A4A4D;
}

.header .on-off {
  height: 100%;
  line-height: 96px;
  margin: 0 32px;
  cursor: pointer;
  float: left;
}

.header .on-off i {
  font-size: 30px;
  color: #ffffff;
}

.header .header-line {
  width: 1px;
  height: 40px;
  float: left;
  background: #4A4A4D;
  margin-top: 20px;
}

.header .up-show {
  float: left;
  height: 100%;
  margin-left: 32px;
}

.up-show-big {
  height: 100%;
  font-size: 26px;
  color: #ffffff;
  font-family: "fz";
  line-height: 80px;
}

.input-serch {
  width: 500px;
  height: 40px;
  border: 2px solid #C4C4C4;
  border-radius: 20px;
  float: left;
  margin-left: 80px;
  margin-top: 19px;
  padding-left: 36px;
  padding-right: 5px;
  background: url(../assets/img/headerVideo/serch.png) no-repeat 10px center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input {
    width: 390px;
    height: 36px;
    background: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    color: #fff
  }

  .serch-button {
    padding: 4px 14px;
    background: #317CEF;
    border-radius: 20px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.header-button-right {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;

  .header-button {
    padding: 6px 14px;
    background: #373946;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  .header-button.active {
    background: #317CEF;
  }

  .header-button:hover {
    background: #317CEF;
  }

  .header-button+.header-button {
    margin-left: 16px;
  }

  .header-more {
    color: #fff;
    margin: 0 24px 0 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      margin: 0 8px 0 0;
    }
  }
}
</style>