import request from "../api/request";
request.interceptors.request.use(
    function(config){
        const token = sessionStorage.getItem("token");
        if (token) {
        config.headers.token = token;
        }
        return config;
    }
);
export default function (data) {
    if(data && typeof data === "object"){
        let userInfo = this.$store.state.user;
        data.operationUserId = userInfo.userId;
        data.operationUser = userInfo.name;
        data.operationTime = this.$calcu.timeCycle(new Date());
        data.facilityType = "pc";
        data.systemName = "数解舆情";
        data.systemType = "8C26D1D18F3DE47B9C92CA533EF1047D";
        data.organizationName = userInfo.mechanism;
        data.organizationId = userInfo.organizationId;
        if(!data.requestUrl){
            data.requestUrl = "";
        }
        if(!data.requestMethod){
            data.requestMethod = "";
        }
        if(!data.requestBody){
            data.requestBody = "";
        }
        if(!data.operationDetails){
            data.operationDetails = "";
        }
        return request({
            url:"log/system/logMonitor/addLog",
            method:'post',
            data: data
        });
    }else{
        this.$message({
            type: "error",
            message: "日志传参格式错误",
          });
        return Promise.reject("error");
    }
}