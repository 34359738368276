<template>
  <div v-if="false" class="common-tools" id="tools" draggable="true" :style="{top: $store.state.commonTools + 'px'}">
    <img src="../assets/img/warning/commonTools.png" />
    <div class="common-tools-cont">
      <span>域名查询</span>
      <span>微博分析</span>
      <span>取证截图</span>
      <span>监测首页</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    warningOff() {
      this.$store.commit("warningOff");
    },
  },
  created() {},
  mounted() {

  }
};
</script>

<style scoped>
.common-tools {
  position: absolute;
  display: flex;
  align-items: flex-start;
  position: fixed;
  right: -104px;
  transition: all 0.5s;
  z-index:30;
}
.common-tools:hover {
  right: 0;
}
.common-tools-cont {
  width: 104px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.12);
  border-radius: 0px 8px 8px 8px;
  position: relative;
  top: 7px;
  padding: 24px;
}
.common-tools-cont span {
  cursor: pointer;
  font-size: 14px;
  color: #5a6474;
  display: inline-block;
}
.common-tools-cont span + span {
  margin-top: 24px;
}
</style>