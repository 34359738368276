import fecha from "./data.js";
export default {
  isDate(date) {
    if (date === null || date === undefined) return false;
    if (isNaN(new Date(date).getTime())) return false;
    return true;
  },
  toDate(date) {
    return this.isDate(date) ? new Date(date) : null;
  },
  // 时间转换
  timeCycle(date, format) {
    /* 
            格式	含义	备注	举例
            yyyy	年		        2017
            M	    月	    不补0	1
            MM	    月		        01
            d	    日	    不补0	2
            dd	    日		        02
            H	    小时	不补0	3
            HH	    小时	    	03
            m	    分钟	不补0	4
            mm	    分钟		    04
            s	    秒	    不补0	5
            ss	    秒		        05 
        */
    date = this.toDate(date);
    if (!date) return "";
    return fecha.format(date, format || "default");
  },
  calcuTime(n) {
    let d = new Date();
    let year = d.getFullYear();
    let mon = d.getMonth() + 1;
    let day = d.getDate();
    if (day <= n) {
      if (mon > 1) {
        mon = mon - 1;
      } else {
        year = year - 1;
        mon = 12;
      }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    let timer =
      year +
      "-" +
      (mon < 10 ? "0" + mon : mon) +
      "-" +
      (day < 10 ? "0" + day : day);
    return timer;
  },
  getDays(strDateStart, strDateEnd) {
    let strSeparator = "-";
    //日期分隔符
    let oDate1;
    let oDate2;
    let iDays;
    oDate1 = strDateStart.split(strSeparator);
    oDate2 = strDateEnd.split(strSeparator);
    let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
    let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
    iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24);
    //把相差的毫秒数转换为天数
    return iDays;
  },
  // 舆情走势时间计算
  getTimeSentiment(data, num, type,myDate) {
    let DataList = [];
    myDate.setDate(myDate.getDate() - data);
    let dateArray = [];
    let flag = 1;
    let getMonth;
    let getDate;
    for (let i = 0; i < num; i++) {
      getMonth =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      getDate =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      dateArray.push(getMonth + "-" + getDate);
      myDate.setDate(myDate.getDate() + flag);
    }
    let nowHours = myDate.getHours();
    let nowDate = dateArray.length - 1;

    for (let i = 0; i < dateArray.length; i++) {
      for (let y = 0; y < 24; y++) {
        if (nowDate == i) {
          if (nowHours + 1 == y) {
            break;
          }
        }
        if (y <= 9) {
          if (type == 1) {
            DataList.push({
              time: dateArray[i] + " " + "0" + y + ":00",
              fyjNum0: 0,
              fyjNum1: 0,
              fyjNum2: 0,
              yjNum0: 0,
              yjNum1: 0,
              yjNum2: 0,
            });
          } else {
            DataList.push({
              time: dateArray[i] + " " + "0" + y + ":00",
              fyjfmgNum: 0,
              fyjmgNum: 0,
              yjfmgNum: 0,
              yjmgNum: 0,
            });
          }
        } else {
          if (type == 1) {
            DataList.push({
              time: dateArray[i] + " " + y + ":00",
              fyjNum0: 0,
              fyjNum1: 0,
              fyjNum2: 0,
              yjNum0: 0,
              yjNum1: 0,
              yjNum2: 0,
            });
          } else {
            DataList.push({
              time: dateArray[i] + " " + y + ":00",
              fyjfmgNum: 0,
              fyjmgNum: 0,
              yjfmgNum: 0,
              yjmgNum: 0,
            });
          }
        }
      }
    }
    return DataList;
  },
  // 用来计算舆情走势累加和
  getNumSentiment(listType, type) {
    for (let i = 0; i < listType.length; i++) {
      if (i != 0) {
        if (type == 1) {
          listType[i].fyjNum0 += listType[i - 1].fyjNum0;
          listType[i].fyjNum1 += listType[i - 1].fyjNum1;
          listType[i].fyjNum2 += listType[i - 1].fyjNum2;
          listType[i].yjNum0 += listType[i - 1].yjNum0;
          listType[i].yjNum1 += listType[i - 1].yjNum1;
          listType[i].yjNum2 += listType[i - 1].yjNum2;
        } else {
          listType[i].fyjfmgNum += listType[i - 1].fyjfmgNum;
          listType[i].fyjmgNum += listType[i - 1].fyjmgNum;
          listType[i].yjfmgNum += listType[i - 1].yjfmgNum;
          listType[i].yjmgNum += listType[i - 1].yjmgNum;
        }
      }
    }
    return listType;
  },
  // 舆情走势时间数据替换
  getDateList(dataList, sentiment_time, str) {
    let timer;
    dataList.forEach((item) => {
      sentiment_time.forEach((ite) => {
        if (item.showTime) {
          timer = item.showTime.slice(5);
          if (str == "1") {
            if (timer == ite.time) {
              ite.fyjNum0 = item.fyjNum0;
              ite.fyjNum1 = item.fyjNum1;
              ite.fyjNum2 = item.fyjNum2;
              ite.yjNum0 = item.yjNum0;
              ite.yjNum1 = item.yjNum1;
              ite.yjNum2 = item.yjNum2;
            }
          } else {
            if (timer == ite.time) {
              ite.fyjfmgNum = item.fyjfmgNum;
              ite.fyjmgNum = item.fyjmgNum;
              ite.yjfmgNum = item.yjfmgNum;
              ite.yjmgNum = item.yjmgNum;
            }
          }
        }
      });
    });
    return sentiment_time;
  },
  //   综合分析综合数据统计表
  comprehensiveData(tableDatacom, data, str) {
    if (str == 1) {
      this.calculation(tableDatacom, data);
    } else {
      this.calculationJw(tableDatacom, data);
    }
    return tableDatacom;
  },
  //   综合分析综合统计表数据对比
  calculation(tableDatacom, data) {
    tableDatacom.forEach((item) => {
      data.forEach((it) => {
        if (item.mediaLink) {
          if (item.mediaLink == it.mediaLink) {
            this.numData(item, it);
          }
        } else if (item.mediaAttribute) {
          if (item.mediaAttribute == it.mediaAttribute) {
            this.numData(item, it);
          }
        } else {
          if (item.mediaLevel == it.mediaLevel) {
            this.numData(item, it);
          }
        }
      });
    });
    for (let i = 0; i < tableDatacom.length - 1; i++) {
      tableDatacom[tableDatacom.length - 1].yqNum0 += tableDatacom[i].yqNum0;
      tableDatacom[tableDatacom.length - 1].yqNum1 += tableDatacom[i].yqNum1;
      tableDatacom[tableDatacom.length - 1].yqNum2 += tableDatacom[i].yqNum2;
      tableDatacom[tableDatacom.length - 1].yjNumffm +=
        tableDatacom[i].yjNumffm;
      tableDatacom[tableDatacom.length - 1].yjNumfm += tableDatacom[i].yjNumfm;
    }
  },
  numData(item, it) {
    item.yjNumffm = it.yjNumffm;
    item.yjNumfm = it.yjNumfm;
    item.yqNum0 = it.yqNum0;
    item.yqNum1 = it.yqNum1;
    item.yqNum2 = it.yqNum2;
  },
  //   综合分析综合统计表数据对比境外
  calculationJw(tableDatacom, data) {
    tableDatacom.forEach((item) => {
      data.forEach((it) => {
        if (item.mediaType) {
          if (item.mediaType == it.mediaType) {
            this.numDataJw(item, it);
          }
        } else if (item.mediaOwnership) {
          if (item.mediaOwnership == it.mediaOwnership) {
            this.numDataJw(item, it);
          }
        } else if (item.mediaCamp) {
          if (item.mediaCamp == it.mediaCamp) {
            this.numDataJw(item, it);
          }
        } else {
          if (item.languageType == it.languageType) {
            this.numDataJw(item, it);
          }
        }
      });
    });
    for (let i = 0; i < tableDatacom.length - 1; i++) {
      tableDatacom[tableDatacom.length - 1].yqNum += tableDatacom[i].yqNum;
      tableDatacom[tableDatacom.length - 1].yjNum += tableDatacom[i].yjNum;
    }
  },
  numDataJw(item, it) {
    item.yqNum = it.yqNum;
    item.yjNum = it.yjNum;
  },
};
