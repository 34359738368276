import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
/* 状态管理  左侧导航开关   用户信息保存 */
const store = new Vuex.Store({
  state: {
    sidebarStatus:
      JSON.parse(sessionStorage.getItem("sidebarStatus")) == null
        ? true
        : JSON.parse(sessionStorage.getItem("sidebarStatus")),
    user:
      JSON.parse(sessionStorage.getItem("user")) == null
        ? { loginState: false }
        : JSON.parse(sessionStorage.getItem("user")),
    warning:
      sessionStorage.getItem("warning") == null
        ? false
        : sessionStorage.getItem("warning"),
    activeName:
      sessionStorage.getItem("activeName") == "first"
        ? "first"
        : sessionStorage.getItem("activeName"),
    active:
      sessionStorage.getItem("active") == null
        ? ""
        : sessionStorage.getItem("active"),
    commonTools:
      sessionStorage.getItem("commonTools") == null
        ? "133"
        : sessionStorage.getItem("commonTools"),
    // 生成报告传参数
    dataListTopic: {},
    // 评论曲线
    multipleSelection: [],
    // 媒体观点分析
    radio: {},
    // 抽样名称
    sampling: {},
    // 抽样下的观点
    radio2: {},
    //监听是否有预警
    warningInfo: false,
    //是否为试用版
    versionType: "1",
    websocket: "wss://www.yuqing.cn/wss/websocket/"
    // websocket: "wss://test.yuqing.cn/wss/websocket/"
  },
  mutations: {
    sedData(state) {
      state.sidebarStatus = !state.sidebarStatus;
      sessionStorage.setItem(
        "sidebarStatus",
        JSON.stringify(state.sidebarStatus)
      );
    },
    logOut() {
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          loginState: false,
        })
      );
    },
    warningOff(state, data) {
      state.warning = data;
    },
    setWarningInfo(state) {
      if (state.warningInfo === true) {
        state.warningInfo = false;
      } else {
        state.warningInfo = true;
      }
    },
    setVersionType(state, data) {
      state.versionType = data;
    }
  },
});
export default store;