import { Message } from "element-ui";
import { informationOneClickCopy, oneClickCopy,recordCopy } from "../../api/message/list";
export default {
  copyFun(str) {
    let reg = new RegExp("null", "g"); //g代表全部
    if (str) {
      str = str.replace(reg, "无");
    }
    let htmlGPs = new RegExp("<br/>", "g");
    if (str) {
      str = str.replace(htmlGPs, ",");
    }
    let htmlGPss = new RegExp('<em style="color:red">', "g");
    if (str) {
      str = str.replace(htmlGPss, " ");
    }
    let htmlGPssem = new RegExp("</em>", "g");
    if (str) {
      str = str.replace(htmlGPssem, "");
    }
    let htmlGPsss = new RegExp("&nbsp;", "g");
    if (str) {
      str = str.replace(htmlGPsss, "");
    }
    let htmlGPssss = new RegExp("<p>", "g");
    if (str) {
      str = str.replace(htmlGPssss, "");
    }
    let htmlGPsssss = new RegExp("</p>", "g");
    if (str) {
      str = str.replace(htmlGPsssss, "");
    }
    if (str) {
      str = str.replace(/<span style="color:red">/g, "");
      str = str.replace(/<\/span>/g, "");
    }
    // if (str) {
    //   str = str.replace(/[<em style="color:red"></em>/&nbsp;/<p>/</p>]/g, "");
    // }
    var textar = document.createElement("textarea");
    textar.value = str;
    document.body.appendChild(textar);
    textar.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    textar.remove();
    Message.success("复制成功");
    recordCopy();
  },
  async customCopy(data) {
    let res = await oneClickCopy(data);
    this.copyFun(res.data.msg);
  },
  async customCopy2(data) {
    let res = await informationOneClickCopy(data);
    this.copyFun(res.data.msg);
  }
};
