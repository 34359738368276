<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
	export default {
		name: 'App',
		}
</script>

<style>
.zb_layout_stop{
  background-color: #fdebec !important;
  border-color: #fbd6d9 !important;
  color: #ea3342;
}
.zb_layout_stop .el-icon-info{
  color: #ea3342 !important;
}
</style>