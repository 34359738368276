import request from '@/api/request.js'
// 这里面存关于实时信息的请求
export function messageList(data) {
    return request({
        url: 'api/information/information/listWeb',
        method: 'Post',
        data: data
    })
}
export function reportList(data) {
    return request({
        url: 'api/information/information/reportList',
        method: 'Post',
        data: data
    })
}
export function getMsginfo(data) {
    return request({
        url: "api/information/information/getInfo",
        method: 'post',
        data: data
    })
}
export function getIncident(data) {
    return request({
        url: "api/system/event/list",
        method: 'post',
        data: data
    })
}
export function dataReport(data) {
    return request({
        url: "api/msgquery/report",
        method: 'post',
        data: data
    })
}
//   筛选条件查询
export function conditionList(data) {
    return request({
        url: 'api/system/regional/PublicSentimentQuery',
        method: 'post',
        data: data
    })
}

//实时信息预警方式保存
export function saveWarningSetting(data) {
    return request({
        url: 'api/information/information/saveWarningSetting',
        method: 'post',
        data: data
    })
}

//降噪设置保存
export function saveInforAccurateSetting(data) {
    return request({
        url: 'api/information/information/saveInforAccurateSetting',
        method: 'post',
        data: data
    })
}

//获取降噪设置
export function getInforAccurateSetting() {
    return request({
        url: 'api/information/information/getInforAccurateSetting',
        method: 'get'
    })
}

//获取编辑监测权限
export function getWarningSettingFlag() {
    return request({
        url: 'api/information/information/getWarningSettingFlag',
        method: 'get'
    })
}

//获取编辑监测设置
export function getWarningSetting() {
    return request({
        url: 'api/information/information/getWarningSetting',
        method: 'get'
    })
}
//获取复制模板
export function getCustomCopy(data) {
    return request({
        url: 'api/msgquery/CustomCopy/getCustomCopy?organId=' + data.organId,
        method: 'GET',
    })
}
//保存复制模板
export function setCustomCopy(data) {
    return request({
        url: 'api/msgquery/CustomCopy/setCustomCopy',
        method: 'POST',
        data: data
    })
}
//精准舆情一键复制
export function oneClickCopy(data) {
    return request({
        url: 'api/msgquery/CustomCopy/oneClickCopy',
        method: 'POST',
        data: data
    })
}
//实时信息一键复制
export function informationOneClickCopy(data) {
    return request({
        url: 'api/information/CustomCopy/oneClickCopy',
        method: 'POST',
        data: data
    })
}
//复制记录
export function recordCopy() {
    return request({
        url: 'api/monitor/monitor/copy',
        method: 'get',
    })
}
