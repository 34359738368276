<template>
  <div class="goOut">
    <el-dialog
    :modal-append-to-body='false'
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="text">您确认要退出系统吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureClick">确定退出</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  props: {

  },
  methods: {
    handleClose(done) {
      done();
    },
    openDialog() {
      this.dialogVisible = true;
     
    },
    sureClick() {
      this.$emit("goLogin");
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.text {
  color: #333333;
  font-size: 16px;
}
</style>