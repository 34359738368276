import Vue from "vue";
import App from "./App.vue";
import service from "./api/request";
import "./assets/css/theme/index.css";
//element-ui  组件库引入
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI);
//引入 状态管理 vuex 配置文件
import store from "./store/index";
// 路由router引入
import router from "./router";
import calcu from "./utils/calcuData.js";
import logFun from "./utils/logFun.js";
Vue.config.productionTip = false;
Vue.prototype.$http = service;
Vue.prototype.$calcu = calcu;
Vue.prototype.$log = logFun;
// 引入重置样式文件
import "./assets/css/public.css";
import publicFun from "./assets/js/index";
import vAvoidRepeat from "./utils/preventReClick"; //防多次点击，重复提交
Vue.use(vAvoidRepeat);
//瀑布流
import {VueMasonryPlugin} from 'vue-masonry';
Vue.use(VueMasonryPlugin)
Vue.prototype.$publicFun = publicFun;
Vue.prototype.resetSetItem = function(key, newVal) {
  //实时监听StorageEvent事件
  var newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function(k, val) {
      sessionStorage.setItem(k, val);
      newStorageEvent.initStorageEvent(
        "setItem",
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
//全局注入 可以$前缀使用  this.$router
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
