<template>
  <div class="header">
    <div class="on-off">
      <img src="../assets/img/header/fold.png" @click="sedData" v-if="$store.state.sidebarStatus" />
      <img src="../assets/img/header/unfold.png" @click="sedData" v-else />
    </div>
    <div class="header-line"></div>
    <div class="up-show">
      <p class="up-show-big">
        <span>{{ $store.state.user.upDispaly ? $store.state.user.upDispaly : '广域全媒体信息监测系统' }}</span>
        <!-- <span v-if="showing" class="img-box">
          <img src="../../src/assets/img/header/sy.png" alt />
        </span> -->
      </p>
      <p class="up-show-small">{{ $store.state.user.mechanism }}</p>
    </div>
    <div class="userinfo">
      <div class="userinfo-user">
        <p>{{ $store.state.user.name }}，欢迎登录！</p>
        <p>{{ dataTime }}</p>
      </div>
      <img src="../assets/img/header/daping.png" v-if="$store.state.user.organizationId === 4652" class="collect"
        @click="goBig" />
      <img src="../assets/img/header/collect.png" class="collect" @click="myFavorite" />
      <div class="userinfo-out" @click="logOut">
        <img src="../assets/img/header/quit.png" />
      </div>
    </div>
    <div id="zb_header_stop">
      <el-image v-if="$store.state.versionType == '2'" :src="require('../assets/img/index/loginTrial.png')"></el-image>
      <el-image v-if="$store.state.versionType == '3'" :src="require('../assets/img/index/loginStop.png')"></el-image>
    </div>
    <goOut ref="goOut" @goLogin="goLogin"></goOut>
  </div>
</template>

<script>
import goOut from "../components/goOut";
export default {
  data() {
    return {
      dataTime: "",
      showing: false,
      token: sessionStorage.getItem("token"),
      organId: this.$store.state.user.organizationId,
      areaId: this.$store.state.user.areaId
    };
  },
  methods: {
    sedData() {
      this.$store.commit("sedData");
    },
    getTime() {
      this.dataTime = this.$calcu.timeCycle(
        new Date(),
        "yyyy年MM月dd日 HH:mm:ss"
      );
      setTimeout(() => {
        this.getTime();
      }, 1000);
    },
    logOut() {
      this.$refs.goOut.openDialog();
    },
    goLogin() {
      sessionStorage.clear();
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        this.$store.commit("logOut");
        this.$router.push({ path: "/login" });
        loading.close();
      }, 2000);
    },
    myFavorite() {
      this.$router.push({ path: "/systemSettings/index" });
      sessionStorage.setItem("activeName", "five");
      this.$store.state.activeName = "five";
      if (sessionStorage.getItem("setting") == "yes") {
        location.reload();
      }
    },
    goBig() {
      window.open(`https://www.yuqing.cn/dapingnew/#/index?Token=${sessionStorage.getItem("token")}`, "_blank");
    }
  },
  components: {
    goOut
  },
  created() {
    this.getTime();
    let time = this.$calcu.timeCycle(new Date(), "yyyy/MM/dd");
    time = new Date(time).getTime();
    if (this.$store.state.user.servicePeriod) {
      let expirationTime = this.$store.state.user.servicePeriod.replace(
        /-/g,
        "/"
      );
      expirationTime = new Date(expirationTime).getTime();
      if (time > expirationTime) {
        this.showing = true;
      } else {
        this.showing = false;
      }
    }
  },
  mounted() { }
};
</script>

<style scoped>
@font-face {
  font-family: "fz";
  src: url("../assets/font/FZZCHJW.otf");
  font-weight: normal;
  font-style: normal;
}

.header {
  height: 100%;
  width: 100%;
  background: linear-gradient(114deg, #16aef3 0%, #027ffe 100%);
  padding: 0;
  min-width: 1625px;
  z-index: 9999;
}

.header .on-off {
  height: 100%;
  line-height: 96px;
  margin: 0 32px;
  cursor: pointer;
  float: left;
}

.header .on-off i {
  font-size: 30px;
  color: #ffffff;
}

.header .header-line {
  width: 1px;
  height: 40px;
  float: left;
  background: #138cc2;
  margin-top: 20px;
}

.header .up-show {
  float: left;
  height: 100%;
  margin-left: 32px;
  padding-top: 4px;
}

.up-show-big {
  height: 42px;
  font-size: 26px;
  color: #ffffff;
  font-family: "fz";
}

.up-show-small {
  font-size: 14px;
  color: #ffffff;
}

.header .userinfo {
  float: right;
  height: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
}

.header .userinfo-user {
  text-align: right;
}

.header .userinfo-user p {
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}

.header .userinfo-out {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}

.header .userinfo-out p {
  font-size: 16px;
  color: #ffffff;
  margin-left: 8px;
}

.header .collect {
  margin-left: 40px;
  cursor: pointer;
}

.header .logo {
  width: 80px;
  height: 80px;
  float: left;
  margin-left: 22px;
}

.img-box {
  position: relative;
  margin-left: 17px;
  top: 2px;
}

.img-box img {
  line-height: 36px;
  width: 60px;
  height: 24px;
}

#zb_header_stop {
  float: right;
  margin-top: 20px;
}
</style>