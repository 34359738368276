// 首页api
import request from "@/api/request.js";
// 这里面存关于用户的请求
export function mediaData(data) {
  return request({
    url: "api/msgquery/customerHome/homePageMediaDistribution",
    method: "Post",
    data: data,
  });
}
// 调性分布
export function tonality(data) {
  return request({
    url: "api/msgquery/customerHome/homePageTonalityDistribution",
    method: "Post",
    data: data,
  });
} // 舆情走势
export function homePageYuQTrend(data) {
  return request({
    url: "api/msgquery/customerHome/homePageYuQTrend",
    method: "Post",
    data: data,
    dataType: 'JSONP'
  });
}
// // 监测数据列表
// export function monitorData(data,url) {
//   return request({
//     url: "api/monitor/monitor/selectMonitorList"+url,
//     method: "Post",
//     data: data,
//   });
// }

export function monitorData(data, url) {
  return request({
    url: "api/datastudy/pushData/list" + url,
    method: "Post",
    data: data,
  });
}
export function monitorIndex(data) {
  return request({
    url: "api/monitor/monitor/selectMonitorDataById",
    method: "Post",
    data: data,
  });
}
export function topicList(data) {
  return request({
    url: "api/topic/analysis/list",
    // url: "api/topic/analysis/kelist",
    method: "post",
    data: data,
  });
}
//查询是否试用机构
export function organType(data) {
  return request({
    url: "api/organization/organization/organType",
    method: "get",
    params: data,
  });
}
//查询试用机构剩余时间
export function organTime(data) {
  return request({
    url: "api/organization/organization/organTime",
    method: "get",
    params: data,
  });
}
export function wxDataQuery(data) {
  return request({
    url: "api/datastudy/study/wxDataQuery",
    method: "post",
    data: data,
  });
}
// 保存数据
export function serchSave(data) {
  return request({
    url: 'api/msgquery/scheme',
    method: 'post',
    data: data
  })
}
// 查询保存数据
export function selectDataSchemeList(data) {
  return request({
    url: 'api/msgquery/scheme/selectDataSchemeList',
    method: 'post',
    data: data
  })
}
// 删除保存数据
export function remove(data) {
  return request({
    url: 'api/msgquery/scheme/remove',
    method: 'delete',
    data: data
  })
}
// 修改保存数据
export function serchEdit(data) {
  return request({
    url: 'api/msgquery/scheme/edit',
    method: 'put',
    data: data
  })
}
